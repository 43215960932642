import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, SEO, RichText } from '@src/components';
import { Gravestone } from '@src/svgs';
import { SanityLink } from '@src/utils';
import styles from './styles/404.module.scss';

const NotFoundPage = ({ data: { page } }) => {
  const backLink = new SanityLink(page.backLink);

  return (
    <Fragment>
      <SEO seo={page.seo} title={page.title} />
      <div className={styles.content}>
        <div className={styles.gravestone}>
          <Gravestone />
        </div>
        <h1 className={styles.title}>{page.title}</h1>
        <RichText className={styles.message} content={page.message} />
        <Link className={styles.backLink} to={backLink.to}>
          {backLink.text}
        </Link>
      </div>
    </Fragment>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }).isRequired,
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageQuery {
    page: sanityNotFoundPage {
      title
      message: _rawMessage(resolveReferences: { maxDepth: 10 })
      backLink {
        ...SanityLink
      }
      seo {
        ...SanitySeo
      }
    }
  }
`;
